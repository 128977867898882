import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import {
  EnglishMCQSFormat,
  MCQSFormat,
  MathMCQSFormat,
  PhysicsMCQSFormat,
  ChemistryMCQSFormat,
  BioMCQSFormat,
  EngMCQSFormat,
} from "./MCQS";
import {
  EnglishFedralLongQuestions,
  EnglishLongQuestions,
  EnglishShortQuestions,
  LongBiologyQuestions,
  LongChemistryQuestions,
  LongEnglishQuestions,
  LongMathQuestions,
  LongPhysicsQuestions,
  LongQuestions,
  ShortQuestions,
} from "./Questions";
import PaperStudentEntryKPK from "./PaperStudentEntryKPK";
import PaperStudentEntryPunjab from "../Punjab/PaperStudentEntryPunjab";

const FaderalPaperFormat = ({ data, showMcqAnswer }) => {
  const isEnglish = ["1", "11"].includes(
    `${data?.paper_details[0]?.subject_id}`
  );

  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  const isMcqExist = !!(data?.paperMcqs.length > 0);

  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );

  const isPunjab = data?.paper_details[0].board_type == "Punjab";
  const boardCheck = data?.paper_details[0]?.paper_board_type_id;
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const is10 = data?.paper_details[0]?.class_id === 2;
  return (
    <div style={{ color: "black", fontSize: "22px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            {/* <PaperHeader data={data} />  */}
            <PaperHeader data={data} />
            {boardCheck === 1 && subjectCheck === 13 ? (
              <PaperStudentEntryPunjab
                boardCheck={boardCheck}
                subjectCheck={subjectCheck}
                data={data}
              />
            ) : (
              // <PaperStudentEntryKPK data={data} />
              <PaperStudentEntry data={data} />
            )}

            {is10 && !subjectCheck === 13 && (
              <div className="mb-3">
                <span className="text-decoration-cls font-weight-bold">
                  Attempt Section-A on the same paper and return it to the
                  Examiner within the given time.{" "}
                </span>{" "}
                <br />
                No marks will be rewarded for Cutting, Erasing or Overwriting.
              </div>
            )}

            {subjectCheck === 13 || subjectCheck === 6 || subjectCheck === 7 ? (
              <div className="row clearfix font-weight-bold ">
                <div className="col-4">
                  Time Allowed: {data?.paper_details[0].objective_time}
                </div>
                <div
                  className={`${
                    !is10 && subjectCheck === 13 && "text-decoration-cls"
                  }  col-4 text-center`}
                >
                  <p>
                    <span style={{ textDecoration: "underline" }}>
                      SECTION-A
                      <span className="font-weight-bold">(Marks 12)</span>
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className=" text-decoration-cls col-12 text-center">
                    SECTION-A
                  </div>
                </div>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6">
                    Time: {data?.paper_details[0].objective_time}
                  </div>

                  <div className="col-6 text-right">
                    Marks: {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>
            )}
            <div
              style={{
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                padding: "8px",
                marginBottom: "15px",
                marginTop: "10px",
                fontSize: "18px",
                textAlign: "justify",
              }}
            >
              <span className="font-weight-bold"> NOTE:-</span> Section – A is compulsory. All parts of this section are to
              be answered on this page and handed over to the Centre
              Superintendent. Deleting/overwriting is not allowed.{" "}
              <span className="font-weight-bold"> Do not use lead pencil.</span>
            </div>  
            <div className="mb-2">
              <span className="font-weight-bold">Q.1</span> Fill the relevant
              bubble against each question. Each part carries one mark.:{" "}
            </div>
            {(is10 && !subjectCheck === 13) || (is10 && isEnglish) ? (
              <div className="row mx-1">
                <div
                  style={{
                    border: "1px solid black",
                  }}
                  className="col-12 d-flex"
                >
                  <span
                    style={{
                      borderRight: "1px solid black",
                      padding: "0 1rem 0 0",
                    }}
                    className="font-weight-bold "
                  >
                    Note:
                  </span>
                  <ol>
                    <li>
                      Four circles are given with every question. Only fill the
                      circle with correct answer.
                    </li>
                    <li>
                      Use blue or black pen to shade (fill in) the circles.
                    </li>
                    <li>
                      More than one shaded circle for a question will be
                      considered as wrong answer.
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}

            {((boardCheck === 3 && subjectCheck === 13)) && (
               <PhysicsMCQSFormat data={data} subjectCheck={subjectCheck} />
            )}
            {subjectCheck === 6 && (
              <ChemistryMCQSFormat data={data} subjectCheck={subjectCheck} />
            )}
            {subjectCheck === 7 && (
              <BioMCQSFormat data={data} subjectCheck={subjectCheck} />
            )}
            {subjectCheck === 11 && (
              <EngMCQSFormat data={data} subjectCheck={subjectCheck} />
            )}
            {subjectCheck === 12 && (
              <MathMCQSFormat data={data} subjectCheck={subjectCheck} />
            )}
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          {subjectCheck === 6 || subjectCheck === 7 ? (
            // <PaperStudentEntryKPK data={data} />
            <></>
          ) : (
            <></>
          )}
          {subjectCheck !== 6 || subjectCheck !== 7 ? (
            <PaperStudentEntry data={data} />
          ) : (
            <>
              {subjectCheck === 13 || subjectCheck === 12 ? (
                <div className="row clearfix mb-3 font-weight-bold ">
                  <div className="col-4">
                    Time Allowed: {data?.paper_details[0].subjective_time}
                  </div>
                  <div
                    className={`${
                      !is10 && subjectCheck === 12 && "text-decoration-cls"
                    }  col-4 text-center`}
                  >
                    SECTION-B
                  </div>
                  <div className="col-4 text-right">
                    Marks: {data?.section[0]?.marks}
                  </div>
                </div>
              ) : (
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6">
                    Time: {data?.paper_details[0].subjective_time}
                  </div>
                  {/* <div className="col-4 text-center">SUBJECTIVE</div> */}
                  <div className="col-6 text-right">
                    Total Marks: {data?.paper_details[0].subjective_marks}
                  </div>
                </div>
              )}
            </>
          )}

          {!(subjectCheck === 13 || subjectCheck === 12) && (
            <>
              {!!data?.section[0]?.text && (
                <div
                  className={`mb-3 py-2 ${
                    subjectCheck === 6 || subjectCheck === 7
                      ? ""
                      : "borderTop borderBottom"
                  }`}
                >
                  <span className="font-weight-bold">Note: </span>
                  {data?.section[0]?.text}
                </div>
              )}
            </>
          )}

          {isShortQuestionsExist &&
            !(
              subjectCheck === 13 ||
              subjectCheck === 12 ||
              subjectCheck === 6 ||
              subjectCheck === 7 ||
              subjectCheck === 11
            ) && (
              <div className="text-decoration-cls text-center font-weight-bold">
                {isEnglish ? "SECTION-B" : "PART-I"}
              </div>
            )}

          {boardCheck === 3 && subjectCheck === 12 && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="font-weight-bold">Time Allowed: 2:40 hours</div>
              <div className="font-weight-bold">Total Marks:60</div>
            </div>
          )}

          {boardCheck === 3 &&
            (subjectCheck === 13 ||
              subjectCheck === 6 ||
              subjectCheck === 11 ||
              subjectCheck === 7) && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="font-weight-bold">
                  {subjectCheck === 13
                    ? "Time Allowed: 2:45 hours"
                    : "Time Allowed: 2:40 hours"}
                </div>
                <div className="font-weight-bold">
                  {subjectCheck === 11 ? "Total Marks: 60" : "Total Marks: 53"}
                </div>
              </div>
            )}

          {(boardCheck === 3 && subjectCheck === 13) ||
          subjectCheck === 6 ||
          subjectCheck === 7 ? (
            <div
              style={{
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                padding: "8px",
                marginBottom: "15px",
                marginTop: "10px",
                fontSize: "18px",
                textAlign: "justify",
                fontWeight: "bold",
              }}
            >
              NOTE:- Answer all parts from Section ‘B’ and all questions from
              Section ‘C’ on the E-sheet. Write your answers on the
              allotted/given spaces.
            </div>
          ) : (
            <div
              style={{
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
                padding: "8px",
                marginBottom: "15px",
                marginTop: "10px",
                fontSize: "18px",
                textAlign: "justify",
              }}
            >
              <span className="font-weight-bold">Note:</span> Answer the
              questions in sections ‘B’ and ‘C’ at the place specified for it
              therein on the separately provided E-sheet. Write your answers
              neatly and legibly.
            </div>
          )}

          <div
            className={`${
              (isEnglish || (!is10 && subjectCheck === 12)) &&
              "text-decoration-cls"
            }  text-center font-weight-bold m-2 ${
              subjectCheck === 7 && "text-decoration-cls"
            }`}
          >
            {/* {isEnglish
              ? "SECTION B"
              : subjectCheck === 13 ||
                subjectCheck === 6 ||
                subjectCheck === 7
                ? <p>SECTION-B<span className="font-weight-light">(Marks 33)</span></p>
                : <p>SECTION-B<span className="font-weight-light">(Marks 36)</span></p>} */}
            {subjectCheck === 13 || subjectCheck === 6 || subjectCheck === 7 ? (
              <p>
                SECTION-B<span className="font-weight-light">(Marks 33)</span>
              </p>
            ) : subjectCheck === 11 ? (
              <p>
                SECTION-B<span className="font-weight-light">(Marks 40)</span>
              </p>
            ) : (
              <p>
                SECTION-B<span className="font-weight-light">(Marks 36)</span>
              </p>
            )}
          </div>
          {subjectCheck === 11 ? (
            <EnglishShortQuestions
              is10={is10}
              subject_id={data?.paper_details[0]?.subject_id}
              data={data?.section[0]}
              isMcqExist={isMcqExist}
              subjectCheck={subjectCheck}
            />
          ) : (
            <ShortQuestions
              is10={is10}
              subject_id={data?.paper_details[0]?.subject_id}
              data={data?.section[0]}
              isMcqExist={isMcqExist}
              subjectCheck={subjectCheck}
            />
          )}

          {is10 && subjectCheck === 12 ? (
            <div className="row clearfix mb-3 font-weight-bold ">
              <div className="col-12 text-right">Note : {data?.note}</div>
            </div>
          ) : (
            <></>
          )}
          {is10 && subjectCheck === 12 && !!data?.section[1]?.text && (
            <>
              <div className="mb-3">
                <span className="font-weight-bold">NOTE: </span>
                {data?.section[1]?.text}
              </div>
            </>
          )}

          {boardCheck === 3 && subjectCheck === 11 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center", // Ensures the text is also centered
                }}
              >
                <div className="font-weight-bold">SECTION – C</div>
                <div>(Marks: 20)</div>
              </div>
            </>
          )}

          {subjectCheck !== 11 &&
            ((boardCheck === 3 && subjectCheck === 7) ||
            subjectCheck === 6 ||
            subjectCheck === 13 ? (
              <LongBiologyQuestions
                subjectCheck={subjectCheck}
                data={data?.section[1]}
                startIndex={data?.section[0]?.paperShortQuestions?.length || 0}
                isMcqExist={isMcqExist}
              />
            ) : (
              <LongMathQuestions
                subjectCheck={subjectCheck}
                data={data?.section[1]}
                startIndex={data?.section[0]?.paperShortQuestions?.length || 0}
                isMcqExist={isMcqExist}
              />
            ))}

          {!is10 && subjectCheck === 12 && !!data?.section[1]?.text && (
            <>
              <div className="row clearfix mb-3">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    fontSize: "22px",
                    marginBottom: "15px",
                    justifyContent: "space-between",
                    marginLeft: "20px", // Added left margin
                    marginRight: "20px", // Added right margin
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      fontWeight: "bold",
                    }}
                  >
                    Note: {data?.section[1]?.text}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      minWidth: "120px",
                    }}
                  >
                    {data?.section[1]?.marks}
                  </div>
                </div>
              </div>
            </>
          )}

          {is10 && !!data?.section[1]?.text && (
            <>
              <div className="row clearfix mb-3  ">
                <div className="col-10">{data?.section[1]?.text}</div>
                <div className="col-2 text-right font-weight-bold">
                  Marks: {data?.section[1]?.marks}
                </div>
              </div>
            </>
          )}

          <>
            {subjectCheck === 11 ? (
              <EnglishFedralLongQuestions
                is10={is10}
                subjectCheck={subjectCheck}
                data={data?.section[1]}
                startIndex={data?.section[0]?.paperShortQuestions?.length || 0}
                isMcqExist={isMcqExist}
              />
            ) : (
              <>{/* Add any fallback or additional content here if needed */}</>
            )}
          </>

          {/* )} */}
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            <PaperStudentEntry data={data} />

            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-4">
                Time: {data?.paper_details[0].objective_time}
              </div>
              <div className="col-4 text-center">OBJECTIVE ANSWER SHEET</div>
              <div className="col-4 text-right">
                Total Marks: {data?.paper_details[0].objective_marks}
              </div>
            </div>

            <div className="mb-3">
              <span className="font-weight-bold">Note:- </span>
              {data?.paper_details[0].objective_note}
            </div>

            {isEnglish ? (
              <EnglishMCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            ) : (
              <MCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FaderalPaperFormat;
