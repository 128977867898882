import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import { PunjabMCQSFormat } from "./MCQS";
import { PunjabUrduShortQuestions, PunjabUrduLongQuestions } from "./Questions";
import { convertToRoman } from "utils/convertToRoman";
import PaperStudentEntrySindh from "../SindhUrdu/PaperStudentEntrySindh";
import PaperStudentEntrySindhIslamiat from "../Islamiyat/PaperStudentEntrySindhIslamiat";

const PunjabTadreesQuranPaperFormat = ({ data, showMcqAnswer }) => {
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const isMcqExist = !!(data?.paperMcqs.length > 0);
  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );
  const is10 = data?.paper_details[0]?.class_id === 2;
  const boardType = data?.paper_details[0].paper_board_type_id;
  return (
    <div style={{ color: "black", fontSize: "22px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />
            {/*  */}
            {/* <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} /> */}
            <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />
            <div className="noto-nastaliq-arbi" style={{ direction: "rtl" }}>
              <div className="row clearfix font-weight-bold mb-3">
                <div className="col-6 text-right">
                  مکمل پرچےکےلیےکُل وقت : 2گھنٹے
                </div>
                <div className="col-6 ">
                  مکمل پرچےکےکُل نمبر: {data?.paper_details[0].paper_marks}
                </div>
              </div>
              <div className="row clearfix font-weight-bold mb-3">
                <div className="col-3 text-right">
                  وقت:{data?.paper_details[0].objective_time}
                </div>
                <div className="col-6 text-center text-decoration-cls">
                  حصہ معروضی
                </div>
                <div className="col-3 ">
                  کل نمبر: {data?.paper_details[0].objective_marks}
                </div>
              </div>

              <div className="col-12 text-right font-weight-bold">
                <> 1۔{data?.paper_details[0].objective_note}</>
              </div>
              <PunjabMCQSFormat
                is10={is10}
                subjectCheck={subjectCheck}
                data={data}
                isIslamiat={data?.paper_details[0].subject_id}
              />
            </div>
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          <div className="noto-nastaliq-arbi" style={{ direction: "rtl" }}>
            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-3 text-right">
                وقت:
                {data?.paper_details[0].subjective_time}
              </div>
              <div className="col-6 text-center text-decoration-cls">
                حصّہ دُوُم(انشائی)
              </div>
              <div className="col-3">
                کل نمبر:
                {data?.paper_details[0].subjective_marks}
              </div>
            </div>

            {!!isShortQuestionsExist && (
              <>
                <PunjabUrduShortQuestions
                  subject_id={subjectCheck}
                  class_id={data?.paper_details[0]?.class_id}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              </>
            )}

            {!!isLongQuestionsExist && (
              <>
                <PunjabUrduLongQuestions
                  subject_id={subjectCheck}
                  is10={is10}
                  boardType={boardType}
                  data={data?.section[1]}
                  startIndex={data?.section[0]?.paperShortQuestions.length || 0}
                  isMcqExist={isMcqExist}
                />
              </>
            )}
          </div>
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            <div className="noto-nastaliq-arbi" style={{ direction: "rtl" }}>
              {/* <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} /> */}
              <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />

              <>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6 text-right">کل وقت: ۳ گھنٹے</div>
                  <div className="col-6 ">
                    کل نشانات: {data?.paper_details[0].paper_marks}
                  </div>
                  <div className="col-3 text-right">
                    مختص وقت:{data?.paper_details[0].objective_time}
                  </div>
                  <div className="col-6 text-center  text-decoration-cls">
                    حصہ الف(لازمی) (کثیر الانتخابی سوالات)
                  </div>
                  <div className="col-2 text-right  ">
                    کل نشانات:
                    {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>

              <div className="col-12 text-right font-weight-bold">
                سوال نمبر۱۔الف){data?.paper_details[0].objective_note}
              </div>

              <PunjabMCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PunjabTadreesQuranPaperFormat;
