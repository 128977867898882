import React from "react";
// import "./src/assets/fonts/QuillFonts.css";
import "../../assets/assets/fonts/QuillFonts.css";

// packages
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";

export default function QuilTextEditor({
  placeholder = "Write",
  onChange = () => {},
  value = "",
}) {
  return (
    <ReactQuill
      value={value}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      modules={modules}
      preserveWhitespace={true}
      className="react-quill"
    />
  );
}

// Add custom fonts here
// const Font = Quill.import("formats/font");
// Font.whitelist = [
//   "arial",
//   "courier-new",
//   "georgia",
//   "helvetica",
//   "lucida-sans",
//   "times-new-roman",
//   "verdana",
//   "monospace",
//   "cursive",
//   "fantasy",
//   "impact",
//   "comic-sans-ms",
//   "roboto", 
//   "poppins",
//   "montserrat",
//   "amiri",
//   "mb-lateefi",
//   "al-qalam-quran-majeed-web",
//   "jameel-noori-nastaleeq",
// ];

// Quill.register(Font, true);
// Font.whitelist.forEach(element => {
//   console.log(element);
// });
/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    // [{ font: Font.whitelist.map((font) => (font))}],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "overline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }],
    ["blockquote", "code-block"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};


const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "script",
  "indent",
  "direction",
  "blockquote",
  "code-block",
  "align",
  "link",
];

/* Overline Button Custom */
var Inline = Quill.import("blots/inline");
var icons = Quill.import("ui/icons");
icons["overline"] = `
  <svg viewBox="0 0 18 18">
    <line x1="3" y1="4" x2="15" y2="4" stroke="currentColor" stroke-width="2"/>
    <line x1="6" y1="14" x2="12" y2="14" stroke="currentColor" stroke-width="2"/>
  </svg>
`;

class OverlineBlot extends Inline {
  static create() {
    let node = super.create();
    node.style.textDecoration = "overline";
    return node;
  }

  static formats() {
    return true;
  }
}

OverlineBlot.blotName = "overline";
OverlineBlot.tagName = "SPAN";

Quill.register(OverlineBlot);



