import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import { MCQSFormat, PunjabMCQSFormat } from "./MCQS";
import {
  UrduShortQuestions,
  UrduLongQuestions,
  PunjabUrduShortQuestions,
  PunjabUrduLongQuestions,
} from "./Questions";
import PaperStudentEntrySindh from "./PaperStudentEntrySindh";
import { convertToRoman } from "utils/convertToRoman";
import PaperStudentEntrySindhIslamiat from "../Islamiyat/PaperStudentEntrySindhIslamiat";
import PaperStudentEntrySindhUrdu from "./PaperStudentEntrySindhUrdu";

const SindhPaperFormat = ({ data, showMcqAnswer }) => {
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const isEnglish = ["1", "11"].includes(`${subjectCheck}`);
  const isPunjabUrdu = ["17"].includes(`${subjectCheck}`);
  const isMcqExist = !!(data?.paperMcqs.length > 0);

  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );
  const is10 = data?.paper_details[0]?.class_id === 2;
  const boardType = data?.paper_details[0].paper_board_type_id;
  return (
    <div
      className="noto-nastaliq-urdu"
      style={{ color: "black", fontSize: "22px" }}
    >
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />
            {isPunjabUrdu && (
                <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />
              )}
              {!isPunjabUrdu && (
                <PaperStudentEntrySindhUrdu subjectCheck={subjectCheck} data={data} />
              )}
            {/*  */}
            <div style={{ direction: "rtl" }}>
              

              <>
                {isMcqExist &&
                  isShortQuestionsExist &&
                  isLongQuestionsExist && (
                    <GeneralInstructions subject_id={subjectCheck} />
                  )}
                {boardType === 2 ? (
                  <div className="row clearfix font-weight-bold mb-3">
                    <div className="col-12 text-center">حصہ معروضی</div>
                    <div className="col-6 text-right">
                      وقت:{data?.paper_details[0].objective_time}
                    </div>
                    <div className="col-6 ">
                      کل نمبر: {data?.paper_details[0].objective_marks}
                    </div>
                  </div>
                ) : (
                  <div className="row clearfix font-weight-bold mb-3">
                    {/* <div className="col-6 text-right">کل وقت: ۳ گھنٹے</div> */}
                    <div className="col-6 text-right">.</div>
                    <div className="col-6 ">
                      کل نشانات: {data?.paper_details[0].paper_marks}
                    </div>
                    <div className="col-3 text-right">
                      مختص وقت:{data?.paper_details[0].objective_time}
                    </div>
                    <div className="col-6 text-center  text-decoration-cls">
                      حصہ الف(لازمی) (کثیر الانتخابی سوالات)
                    </div>
                    <div className="col-2 text-right  ">
                      کل نشانات:
                      {data?.paper_details[0].objective_marks}
                    </div>
                  </div>
                )}
              </>

              <MCQInstruction
                boardType={boardType}
                subjectCheck={subjectCheck}
              />

              <div className="col-12 text-right font-weight-bold">
                {boardType === 2 ? (
                  <>
                    {is10 ? (
                      <>{data?.paper_details[0].objective_note}</>
                    ) : (
                      <>سوال نمبر1:{data?.paper_details[0].objective_note}</>
                    )}{" "}
                  </>
                ) : (
                  <> سوال نمبر۱۔الف){data?.paper_details[0].objective_note}</>
                )}
              </div>
              {boardType === 2 && !is10 ? (
                <PunjabMCQSFormat
                  is10={is10}
                  subjectCheck={subjectCheck}
                  data={data}
                />
              ) : (
                <MCQSFormat
                  boardType={boardType}
                  is10={is10}
                  subjectCheck={subjectCheck}
                  data={data}
                />
              )}
            </div>
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          <div style={{ direction: "rtl" }}>
            <div className="row clearfix font-weight-bold mb-3">
              {boardType === 2 ? (
                <>
                  {is10 ? (
                    <>
                      <div className="col-12 text-center">حصہ انشائیہ</div>
                    </>
                  ) : (
                    <>
                      <div className="col-3 text-right">
                        مضمون:{" "}
                        {data?.paperMcqs[0]?.subject_name == "Urdu"
                          ? "اُردو"
                          : data?.paperMcqs[0]?.subject_name}
                      </div>
                      <div className="col-6 text-center">
                        انشائی طرز (حصہ اول)
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="col-12 text-right">
                  مضمون:{" "}
                  {data?.paperMcqs[0]?.subject_name == "Urdu"
                    ? "اُردو"
                    : data?.paperMcqs[0]?.subject_name}
                </div>
              )}
            </div>

            {boardType === 2 && is10 ? (
              <div className="row clearfix font-weight-bold mb-3">
                <div className="col-6 text-right">
                  رول نمبر :ہندسوں میں ________________
                </div>
                <div className="col-6">
                  کُل وقت:
                  {data?.paper_details[0].subjective_time}
                </div>

                <div className="col-6 text-right">
                  الفاظ میں ________________
                </div>
                <div className="col-6">
                  کل نمبر:
                  {data?.paper_details[0].subjective_marks}
                </div>
              </div>
            ) : (
              <div className="row clearfix font-weight-bold mb-3">
                <div className="col-6 text-right">
                  {boardType === 2 ? "وقت:" : "کُل وقت:"}{" "}
                  {data?.paper_details[0].subjective_time}
                </div>
                <div className="col-6">
                  {boardType === 2 ? "کل نمبر:" : "کل نشانات:"}{" "}
                  {data?.paper_details[0].subjective_marks}
                </div>
              </div>
            )}

            {!!isShortQuestionsExist && (
              <>
                {boardType === 4 && (
                  <div className="row clearfix font-weight-bold mb-3">
                    <div className="col-12 text-center mb-2 ">
                      <span className="text-decoration-cls">
                        حصہ ب (مختصر جواب کے سوالات)
                      </span>
                      نشانات :{data?.section[0]?.marks}
                    </div>
                  </div>
                )}

                {boardType === 2 ? (
                  <PunjabUrduShortQuestions
                    subject_id={subjectCheck}
                    class_id={data?.paper_details[0]?.class_id}
                    data={data?.section[0]}
                    isMcqExist={isMcqExist}
                  />
                ) : (
                  <UrduShortQuestions
                    subject_id={subjectCheck}
                    class_id={data?.paper_details[0]?.class_id}
                    data={data?.section[0]}
                    isMcqExist={isMcqExist}
                  />
                )}
              </>
            )}

            {!!isLongQuestionsExist && (
              <>
                <div className="row clearfix font-weight-bold mb-3">
                  {boardType === 2 ? (
                    <>
                      {!is10 && (
                        <div className="col-12 text-center">حصہ دوم</div>
                      )}
                    </>
                  ) : (
                    <div className="col-12 text-center">
                      <span className="text-decoration-cls">
                        حصہ ج( بیانیہ جواب کے سوالات)
                      </span>{" "}
                      نشانات : {data?.section[0]?.marks}
                    </div>
                  )}
                </div>
                {boardType === 2 ? (
                  <PunjabUrduLongQuestions
                    subject_id={subjectCheck}
                    is10={is10}
                    boardType={boardType}
                    data={data?.section[1]}
                    startIndex={
                      data?.section[0]?.paperShortQuestions.length || 0
                    }
                    isMcqExist={isMcqExist}
                  />
                ) : (
                  <UrduLongQuestions
                    subject_id={subjectCheck}
                    boardType={boardType}
                    data={data?.section[1]}
                    startIndex={
                      data?.section[0]?.paperShortQuestions.length || 0
                    }
                    isMcqExist={isMcqExist}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            {isPunjabUrdu && (
                <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />
              )}
              {!isPunjabUrdu && (
                <PaperStudentEntrySindhUrdu subjectCheck={subjectCheck} data={data} />
              )}
            <div style={{ direction: "rtl" }}>
              {/* <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} /> */}
              

              <>
                {isMcqExist &&
                  isShortQuestionsExist &&
                  isLongQuestionsExist && (
                    <GeneralInstructions subject_id={subjectCheck} />
                  )}
                <div className="row clearfix font-weight-bold mb-3">
                  {/* <div className="col-6 text-right">کل وقت: ۳ گھنٹے</div> */}
                  <div className="col-6 text-right">.</div>
                  <div className="col-6 ">
                    کل نشانات: {data?.paper_details[0].paper_marks}
                  </div>
                  <div className="col-3 text-right">
                    مختص وقت:{data?.paper_details[0].objective_time}
                  </div>
                  <div className="col-6 text-center  text-decoration-cls">
                    حصہ الف(لازمی) (کثیر الانتخابی سوالات)
                  </div>
                  <div className="col-2 text-right  ">
                    کل نشانات:
                    {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>
              <MCQInstruction />

              <div className="col-12 text-right font-weight-bold">
                سوال نمبر۱۔الف){data?.paper_details[0].objective_note}
              </div>

              <MCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SindhPaperFormat;

const GeneralInstructions = ({ subject_id }) => {
  const isUrdu = ["17"].includes(`${subject_id}`);

  const UrduPaperGeneralInstruction = () => {
    return (
      <div className="row clearfix mb-3">
        <div className="col-12 font-weight-bold mb-2 text-decoration-cls">
          General Instructions:
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘A’:</span> I It consists
          of 15 Multiple Choice Questions (MCQs) and all of them are to be
          answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘B’:</span> It consists of
          05 Short- Answer Questions and all of them are to be answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘C’:</span> It consists of
          04 Descriptive Answer-Questions and all of them are to be answered.
        </div>
      </div>
    );
  };

  if (isUrdu) return <></>;
};

const MCQInstruction = ({ boardType, subjectCheck }) => {
  const instructions = [
    {
      line: "تمام سوالات کے جوابات مطلوب ہیں ۔",
    },
    {
      line: "سوالات کاپی پر نقل نہ کیجیے۔پرچے کے مطابق صحیح نمبر ڈال کر جواب لکھیے۔",
    },
    {
      line: "ہر سوال کا ایک نمبر ہے۔",
    },
    {
      line: "اپنے پرچے کا کوڈ نمبر واضح طور سے جلی حروف میں اپنی جوابی کاپی کی ابتدا میں لکھیے۔",
    },
  ];
  return (
    <div className="row clearfix mb-3">
      {boardType === 2 ? (
        <>
          <div className={`col-12 text-right mb-2`}>نوٹ:</div>
        </>
      ) : (
        <>
          <div className={`col-1 mb-2`}>ہدایات:</div>
          <div className="">
            {instructions.map((ins, index) => {
              return (
                <div className="d-flex">
                  <p>{`${convertToRoman(index + 1)})`}</p>
                  <p>{ins.line}</p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
