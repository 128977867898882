import React from "react";

// packages
import parse from "html-react-parser";

// util
import { convertToRoman } from "utils/convertToRoman";
import { numberToArabicNumerals } from "utils/Helper";

// let doubleCheckforHeading = 0;
export const PunjabUrduLongQuestions = ({
  data,
  is10,
  subject_id,
  startIndex,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  let i = 0;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => {
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 d-flex">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    {ele?.question_ids?.map((ques, index) => {
                      return (
                        <>
                          <span style={{ fontFamily: "auto" }}>{parse(`${ques.question}`)}</span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {/* {ele.ui_style == "bullet_romen" && doubleCheckforHeading === 0 && (
              <>
                {doubleCheckforHeading++}
                <div>منتخب احاديث کا ترجمہ:</div>
              </>
            )} */}
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 text-decoration-cls">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">نشانات:{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span style={{ fontFamily: "auto" }}>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    {ele.text}
                  </div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: "0 0rem 0 0",
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${numberToArabicNumerals(index + 1)}.`}
                        </span>
                        <span style={{ fontFamily: "auto" }}>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const PunjabUrduShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-10 text-right">
          <span>ہدایات:{data?.text}</span>
        </div>
      </div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 d-flex">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <span style={{ fontFamily: "auto" }}>{parse(`${ques.question}`)}</span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 text-decoration-cls">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(index + startingQuestionNumber)}۔
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">نشانات:{ele.marks}</div>
                </div>

                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", marginLeft: "5px", fontWeight: "bold" }}
                        >
                          {`(${convertToRoman(index + 1)})`}
                        </span>
                        <span  style={{ fontFamily: "auto" }}>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
