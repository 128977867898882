export const formatDate = (inputDate) => {
  if (!inputDate) return null;
  const hasTime = /\d{2}:\d{2}/.test(inputDate);
  const options = hasTime
    ? {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }
    : { year: "numeric", month: "2-digit", day: "2-digit" };

  return new Date(inputDate).toLocaleString("en-US", options);
};

export function formattedDate(date) {
  return new Date(date).toLocaleDateString("en-GB"); // "en-GB" formats as DD/MM/YYYY
};

export function intToRoman(num) {
  const val = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const syb = [
    "M",
    "CM",
    "D",
    "CD",
    "C",
    "XC",
    "L",
    "XL",
    "X",
    "IX",
    "V",
    "IV",
    "I",
  ];
  let romanNum = "";
  for (let i = 0; i < val.length; i++) {
    while (num >= val[i]) {
      romanNum += syb[i];
      num -= val[i];
    }
  }
  return romanNum;
}
export function intToRomanLower(num) {
  const val = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const syb = [
    "m",
    "cm",
    "d",
    "cd",
    "c",
    "xc",
    "l",
    "xl",
    "x",
    "ix",
    "v",
    "iv",
    "i",
  ];
  let romanNum = "";
  for (let i = 0; i < val.length; i++) {
    while (num >= val[i]) {
      romanNum += syb[i];
      num -= val[i];
    }
  }
  return romanNum;
}
export function numberToRoman(num) {
  if(num === 1) { num++; return "I" };
  if(num === 2) { num++;  return "II" };
  if(num === 3) { num++;  return "III" };
  if(num === 4) { num++;  return "IV" };
  if(num === 5) { num++;  return "V" };
  if(num === 6) { num++;  return "VI" };
  if(num === 7) { num++;  return "VII" };
  if(num === 8) { num++;  return "VIII" };
  if(num === 9) { num++;  return "IX" };
  if(num === 10) { num++;  return "X" };
}

export function numberToAlphabet(n) {
  const base = 26;
  let result = "";
  n -= 1;
  while (n >= 0) {
    let currentLetter = String.fromCharCode((n % base) + 97);
    result = currentLetter + result;
    n = Math.floor(n / base) - 1;
  }
  return `${result}`;
}


export function numberToArabicNumerals(input) {
  // Mapping of Western Arabic digits to Eastern Arabic digits
  const arabicNumerals = {
      '0': '٠',
      '1': '١',
      '2': '٢',
      '3': '٣',
      '4': '٤',
      '5': '٥',
      '6': '٦',
      '7': '٧',
      '8': '٨',
      '9': '٩'
  };

  // Convert input to a string and replace each digit with its Arabic counterpart
  return input.toString().replace(/\d/g, digit => arabicNumerals[digit]);
}
export function numberToAlifNumerals(input) {
  // Mapping of Western Arabic digits to Eastern Arabic digits
  const arabicNumerals = { 
      '1': 'الف',
      '2': 'ب',
      '3': 'ج',
      '4': 'د', 
  };

  // Convert input to a string and replace each digit with its Arabic counterpart
  return input.toString().replace(/\d/g, digit => arabicNumerals[digit]);
}

export function subjectNameToUrdu(subjectName) {
  if(subjectName == "Islamiat" || subjectName == "islamiat" || subjectName == "ISLAMIAT" || subjectName == "Islamiyat") {
    return "اسلاميات";
  }
  if(subjectName == "Urdu" || subjectName == "urdu" || subjectName == "URDU") {
    return "اردو";
  }
}

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  let timeString = ''
  if (minutes > 0) {
    timeString += `${minutes} min`
  }
  if (remainingSeconds > 0) {
    if (timeString.length > 0) {
      timeString += ', '
    }
    timeString += `${remainingSeconds} sec`
  }

  return timeString.length > 0 ? timeString : '0 sec' 
}
