import React from "react";

// packages
import parse from "html-react-parser";

// util
import { convertToRoman } from "utils/convertToRoman";

export const PunjabUrduLongQuestions = ({
  data,
  is10,
  subject_id,
  startIndex,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  let i = 0;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => {
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>{index + startIndex + startingQuestionNumber}۔ </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const PunjabUrduShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-10 text-right">
          <span>{data?.text}</span>
        </div>
        <div className="col-2">{data?.marks}</div>
      </div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>{index + startingQuestionNumber}۔ </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="row w-75 ">
                    {ele?.sq_question_ids?.map((ques, index) => {
                      const isEvenIndex = index % 2 === 0; // Check if the index is even

                      return (
                        <div
                          key={index}
                          className={`col-3 border-cls text-center`}
                        >
                          <span>{parse(`${ques.question}`)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    {index + startingQuestionNumber}۔{ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", marginLeft: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}. `}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
