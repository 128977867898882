import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import { MCQSFormat, PunjabMCQSFormat } from "./MCQS";
import { PunjabUrduShortQuestions, PunjabUrduLongQuestions } from "./Questions";
import { convertToRoman } from "utils/convertToRoman";
import PaperStudentEntrySindh from "../SindhUrdu/PaperStudentEntrySindh";
import PaperStudentEntrySindhIslamiat from "../Islamiyat/PaperStudentEntrySindhIslamiat";

const IslamiyatFormat = ({ data, showMcqAnswer }) => {
  console.log(data, "data islamiyat");
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const isMcqExist = !!(data?.paperMcqs.length > 0);
  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );
  const is10 = data?.paper_details[0]?.class_id === 2;
  const boardType = data?.paper_details[0].paper_board_type_id;
  const isIslamiat = subjectCheck === 19 ? 1 : 0;
  return (
    <div style={{ color: "black", fontSize: "24px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />
            {/*  */}
            {isIslamiat === 1 && (
              <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />
            )}
            {isIslamiat === 0 && (
              <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} />
            )}
            {/* {boardType === 2 && ( */}
              <div className="noto-nastaliq-urdu" style={{ direction: "rtl", textAlign: "start" }}>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6 text-right" style={{ fontSize: "27px" }}>
                  وقت:{data?.paper_details[0].objective_time}
                </div>
                <div className="col-6 " style={{ fontSize: "27px" }}>
                  کل نشانات: {data?.paper_details[0].objective_marks}
                </div>
              </div>

              <div className="col-12 text-right font-weight-bold" style={{ fontSize: "27px" }}>
                عمومی ہدایات:
              </div>
              <div className="col-12 text-right font-weight-bold" style={{ fontSize: "27px" }}>
                حصّہ(الف):۱۵کثیرالانتخابی سوالات پرمشتمل ہےاوران میں سےہرایک
                کاجواب مطلوب ہے۔
              </div>
              <div className="col-12 text-right font-weight-bold" style={{ fontSize: "27px" }}>
                حصّہ(ب): مختصرجوابات کےسوال نمبر۲ تا ۱۲ پرمشتمل ہے۔سوال
                نمبر۲اور۳خالصتاًآیات اور اَحادیث کےصرف تراجم پرمبنی ہےجب کہ سوال
                ۴ تا ۱۲ آیات /اَحادیث کےتراجم کے علاوہ پوری کتاب میں سےترتیب
                دیےگئےہیں۔
              </div>
              <div className="col-12 text-right font-weight-bold" style={{ fontSize: "27px" }}>
                حصّہ(ج): ۶بیانیہ جواب کےسوالات پرمشتمل ہے۔ہرسوال سےمتعلق تفصیلات
                موقع محل پرموجودہیں۔
              </div>

              <div className="row clearfix font-weight-bold mb-3">
                <div className="col-5 text-decoration-cls" style={{ fontSize: "27px" }}>حصّہ الف(لازمی)</div>
                <div className="col-2 text-decoration-cls" style={{ fontSize: "27px" }}>
                  (کثیرالانتخابی سوالات)
                </div>
                <div className="col-5 text-right text-decoration-cls" style={{ fontSize: "27px" }}>
                  کل نشانات: {data?.paper_details[0].objective_marks}
                </div>
              </div>

              <MCQInstruction
                boardType={boardType}
                subjectCheck={subjectCheck}
              />
              <div className="col-12 text-right font-weight-bold">
                <> سوال نمبر۱۔{data?.paper_details[0].objective_note}</>
              </div>

              <MCQSFormat is10={is10} subjectCheck={subjectCheck} data={data} />
            </div>
          
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          {/* <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} /> */}
          {isIslamiat === 1 && (
              <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />
            )}
            {isIslamiat === 0 && (
              <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} />
            )}
          <div className="noto-nastaliq-urdu" style={{ direction: "rtl", textAlign: "start" }}>
            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-6 text-right">
                وقت:
                {data?.paper_details[0].subjective_time}
              </div>

              <div className="col-6">
                کل نشانات:
                {data?.paper_details[0].subjective_marks}
              </div>
            </div>
            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-7 text-decoration-cls">
                حصّہ ب(مختصر جواب کےسوالات)
              </div>
              <div className="col-5 text-center text-decoration-cls">
                نشانات:
                {data?.section[0].marks}
              </div>
            </div>

            {!!isShortQuestionsExist && (
              <>
                <PunjabUrduShortQuestions
                  subject_id={subjectCheck}
                  class_id={data?.paper_details[0]?.class_id}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              </>
            )}
            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-7 text-decoration-cls">
                حصّہ ج(بیانیہ جوابات کےسوالات)
              </div>
              <div className="col-5 text-center text-decoration-cls">
                نشانات:
                {data?.section[1].marks}
              </div>
            </div>
            {!!isLongQuestionsExist && (
              <>
                <PunjabUrduLongQuestions
                  subject_id={subjectCheck}
                  is10={is10}
                  boardType={boardType}
                  data={data?.section[1]}
                  startIndex={data?.section[0]?.paperShortQuestions.length || 0}
                  isMcqExist={isMcqExist}
                />
              </>
            )}
          </div>
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            {isIslamiat === 1 && (
              <PaperStudentEntrySindhIslamiat subjectCheck={subjectCheck} data={data} />
            )}
            {isIslamiat === 0 && (
              <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} />
            )}
            <div className="noto-nastaliq-urdu" style={{ direction: "rtl", textAlign: "start" }}>
              {/* <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} /> */}
              
              <>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6 text-right">کل وقت: ۳ گھنٹے</div>
                  <div className="col-6 ">
                    کل نشانات: {data?.paper_details[0].paper_marks}
                  </div>
                  <div className="col-3 text-right">
                    مختص وقت:{data?.paper_details[0].objective_time}
                  </div>
                  <div className="col-6 text-center  text-decoration-cls">
                    حصہ الف(لازمی) (کثیر الانتخابی سوالات)
                  </div>
                  <div className="col-2 text-right  ">
                    کل نشانات:
                    {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>

              <div className="col-12 text-right font-weight-bold">
                سوال نمبر۱۔الف{data?.paper_details[0].objective_note}
              </div>

              <PunjabMCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IslamiyatFormat;

const MCQInstruction = ({ boardType, subjectCheck }) => {
  const instructions = [
    {
      line: "تمام سوالات کے جوابات مطلوب ہیں ۔",
    },
    {
      line: "سوالات کاپی پر نقل نہ کیجیے۔پرچے کے مطابق صحیح نمبر ڈال کر جواب لکھیے۔",
    },
    {
      line: "ہر سوال کا ایک نمبر ہے۔",
    },
    {
      line: "اپنے پرچے کا کوڈ نمبر واضح طور سے جلی حروف میں اپنی جوابی کاپی کی ابتدا میں لکھیے۔",
    },
  ];
  return (
    <div className="row clearfix mb-3">
      <>
        <div className={`col-1 mb-2`} style={{ fontSize: "27px" }}>ہدایات:</div>
        <div className="" style={{ fontSize: "27px" }}> 
          {instructions.map((ins, index) => {
            return (
              <div className="d-flex">
                <p>{`(${convertToRoman(index + 1)})`}</p>
                <p>{ins.line}</p>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};
