import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

//
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";

const MCQSCol = ({ col, number, text, image, isRightOption, isIsl }) => {
  const active = isRightOption && {
    backgroundColor: "lightgreen",
    border: "1px solid green",
    borderRadius: "1px",
    color: "#fff",
    padding: "0 2px",
  };
  const islStyle = isIsl === 20 && {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <p className={`${col} mcqs_cls`} >
      <span style={{ marginLeft: "0px", marginRight: "26px", textAlign: "center", fontWeight: "500" }}>
        {number}
      </span>
      <span style={{ ...active }}>
        <span style={{ direction: "rtl" }}>{text}</span>

        {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )}
      </span>
    </p>
  );
};

export const PunjabMCQSFormat = ({ subjectCheck, data, showMcqAnswer, isIslamiat }) => {
  return (
    <div className="body mb-3">
      <div className="row clearfix">
        <p className={"col-1 text-center border-cls m-0"}>
          <span className="mcqs_cls2"></span>
        </p>
        <p className={"col-3 text-center border-cls m-0"}>
          <span className="mcqs_cls2"></span>
        </p>
        <p className={"col-2 text-center border-cls m-0"}>
          <span className="mcqs_cls2">A</span>
        </p>
        <p className={"col-2 text-center border-cls m-0"}>
          <span className="mcqs_cls2">B</span>
        </p>
        <p className={"col-2 text-center border-cls m-0"}>
          <span className="mcqs_cls2">C</span>
        </p>
        <p className={"col-2 text-center border-cls m-0"}>
          <span className="mcqs_cls2">D</span>
        </p>
      </div>
      {data?.paperMcqs?.map((ele, index) => (
        <div key={ele.id}>
          <div className="row clearfix ">
            <MCQSCol
              subjectCheck={subjectCheck}
              col="col-1 border-cls"
              number={`${index + 1}`}
              isIsl={isIslamiat}
            />
            <MCQSCol
              subjectCheck={subjectCheck}
              col="col-3 border-cls direction_rtl"
              text={parse(`${ele.description}`)}
              image={ele.file}
            />
            <MCQSCol
              col="col-2 border-cls"
              subjectCheck={subjectCheck}
              text={parse(`${ele.opt_a}`)}
              image={ele.opt_a_file}
              isRightOption={showMcqAnswer && ele.option_answer == "opt_a"}
            />
            <MCQSCol
              subjectCheck={subjectCheck}
              col="col-2 border-cls"
              text={parse(`${ele.opt_b}`)}
              image={ele.opt_b_file}
              isRightOption={showMcqAnswer && ele.option_answer == "opt_b"}
            />
            <MCQSCol
              subjectCheck={subjectCheck}
              col="col-2 border-cls"
              // number={"c. "}
              text={parse(`${ele.opt_c}`)}
              image={ele.opt_c_file}
              isRightOption={showMcqAnswer && ele.option_answer == "opt_c"}
            />
            <MCQSCol
              subjectCheck={subjectCheck}
              col="col-2 border-cls"
              // number={"d. "}
              text={parse(`${ele.opt_d}`)}
              image={ele.opt_d_file}
              isRightOption={showMcqAnswer && ele.option_answer == "opt_d"}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
