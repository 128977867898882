import React from "react";
import { subjectNameToUrdu } from "utils/Helper";

const infoStyle = { 
  border: "1px solid #c5c5c5", 
  padding: "12px",
  minHeight: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  '@media print': {
    border: "1px solid #000",
    padding: "6px",
    minHeight: "40px",
  }
};

const labelStyle = {
  fontWeight: "500",
  fontSize: "16px",
  '@media print': {
    fontWeight: "600",
    fontSize: "15px",
  }
};

// Specific style for Roll No label
const rollNoLabelStyle = {
  ...labelStyle,
  fontSize: "15px", // Smaller font size for Roll No
  '@media print': {
    ...labelStyle['@media print'],
    fontSize: "13px",
  }
};

const PaperStudentEntrySindhIslamiat = ({ subjectCheck, data }) => {
  return (
    <div 
      className="row clearfix" 
      style={{ 
        margin: "0 0px 25px 0px",
        pageBreakInside: "avoid",
        '@media print': {
          margin: "0 0px 15px 0px"
        }
      }}
    >
      {/* First Row */}
      <div className="col-1" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        <span style={labelStyle}>سيکشن</span>
      </div>
      <div className="col-1" style={infoStyle}>
        {data?.paperMcqs[0]?.classname}
      </div>
      <div className="col-1" style={infoStyle}>
        <span style={labelStyle}>گريڈ</span>
      </div>
      <div className="col-6" style={infoStyle}></div>
      <div className="col-2" style={infoStyle}>
        <span style={labelStyle}>نام</span>
      </div>

      {/* Second Row */}
      <div className="col-1" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        <span style={rollNoLabelStyle}>رول نمبر</span>
      </div>
      <div className="col-1" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        <span style={labelStyle}>تاريخ</span>
      </div>
      
      <div className="col-6" style={infoStyle}>
        {subjectNameToUrdu(data?.paperMcqs[0]?.subject_name)}
      </div>
      <div className="col-2" style={infoStyle}>
        <span style={labelStyle}>مضمون</span>
      </div>
      
    </div>
  );
};

export default PaperStudentEntrySindhIslamiat;
